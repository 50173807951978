<script>
export default {
  name: "AppFooter",
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
  i18n: {
    messages: {
      en: {
        footerText: [
          "ASX 200 provides services worldwide in accordance with the regulatory requirements of the relevant client jurisdiction and in accordance with the terms of the licence of each licensed entity within the ASX 200 group of companies. Risk Warning: Derivatives products are highly leveraged, carry a high level of risk and may not be suitable for all investors. Legal documents should be reviewed before dealing with us. The information on this website is not directed at residents of certain jurisdictions and is not intended for distribution to, or use by, any person or entity in any jurisdiction where such distribution or use would be contrary to any local law or regulation of that jurisdiction. Those who access this website do so on their own initiative. This release does not constitute a solicitation of trade.",
        ],
      },
      ru: {
        footerText: [
          "ASX 200 предоставляет услуги по всему миру в соответствии с нормативными требованиями соответствующей юрисдикции клиента и в соответствии с условиями лицензии каждого лицензированного лица в группе компаний ASX 200.Предупреждение о риске: Производные продукты имеют высокую долю заемных средств, несут высокий уровень риска и могут подходить не всем инвесторам. Юридические документы должны быть рассмотрены перед заключением сделок с нами. Информация на этом веб-сайте не предназначена для жителей определенных юрисдикций и не предназначена для распространения или использования любым физическим или юридическим лицом в любой юрисдикции, где такое распространение или использование может противоречить любым местным законам или правилам этой юрисдикции. Те, кто заходит на этот сайт, делают это по собственной инициативе. Релиз не является приглашением к торговле.",
        ],
      },
      fr: {
        footerText: [
          "ASX 200 fournit des services dans le monde entier conformément aux exigences réglementaires de la juridiction concernée du client et conformément aux conditions de licence de chaque entité agréée au sein du groupe de sociétés ASX 200. Avertissement sur les risques: les produits dérivés sont fortement endettés et comportent un niveau de risque élevé. et peut ne pas convenir à tous les investisseurs. Les documents juridiques doivent être examinés avant de conclure des transactions avec nous. Les informations contenues dans ce site Web ne sont pas destinées aux résidents de certaines juridictions et ne sont pas destinées à être distribuées ou utilisées par toute personne ou entité dans une juridiction où une telle distribution ou utilisation peut être contraire aux lois ou réglementations locales de cette juridiction. Ceux qui accèdent à ce site le font de leur propre initiative. La publication n’est pas une invitation au commerce.",
        ],
      },
      de: {
        footerText: [
          "ASX 200 erbringt Dienstleistungen weltweit in Übereinstimmung mit den regulatorischen Anforderungen der jeweiligen Gerichtsbarkeit des Kunden und in Übereinstimmung mit den Lizenzbedingungen jedes lizenzierten Unternehmens innerhalb der ASX 200-Unternehmensgruppe. Risikowarnung: Derivative Produkte sind stark gehebelt und bergen ein hohes Risiko und ist möglicherweise nicht für alle Anleger geeignet. Rechtsdokumente müssen vor Abschluss von Transaktionen mit uns geprüft werden. Die Informationen auf dieser Website richten sich nicht an Einwohner bestimmter Gerichtsbarkeiten und sind nicht für die Weitergabe an oder die Nutzung durch eine natürliche oder juristische Person in einer Gerichtsbarkeit bestimmt, in der eine solche Verbreitung oder Nutzung möglicherweise im Widerspruch zu den örtlichen Gesetzen oder Vorschriften dieser Gerichtsbarkeit steht. Wer auf diese Seite zugreift, tut dies aus eigener Initiative. Die Veröffentlichung stellt keine Aufforderung zum Handel dar.",
        ],
      },
    },
  },
};
</script>

<template>
  <footer class="footer">
    <div data-aos="fade-up" class="container">
      <div class="row row-gap-40">
        <div class="col-xl-7">
          <div class="row row-gap-20">
            <div class="col-md-4">
              <div class="footer__logo mb-20">
                <router-link :to="$localePath({ name: 'Home' })">
                  <img :src="require('@/assets/img/logo.svg')" alt="" />
                </router-link>
              </div>
              <div class="row row-gap-20">
                <div class="col-6 col-md-12">
                  <p class="mb-10">
                    <a
                      href="mailto:support@asxtrades-200.co"
                      class="footer__soc-link"
                    >
                      <span class="ic-mail link-icon"></span>
                      E-mail
                    </a>
                  </p>

                  <a
                    href="tg://resolve?domain=asxtradesup"
                    class="footer__soc-link"
                  >
                    <span class="ic-send link-icon"></span>
                    Telegram
                  </a>
                </div>
                <div class="col-6 col-md-12">
                  <div class="footer__menu">
                    <router-link
                      :to="$localePath({ name: 'Privacy' })"
                      class="menu__item"
                    >
                      {{ $t("privacy") }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="footer__title h3">
                <router-link :to="$localePath({ name: 'Markets' })">
                  {{ $t("menu.markets") }}
                </router-link>
              </div>
              <div class="footer__menu">
                <router-link
                  :to="$localePath({ name: 'Currency' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.currency") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Indices' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.indices") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Stock' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.stock") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Forex' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.forex") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Commodities' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.commodities") }}
                </router-link>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="footer__title h3">
                {{ $t("company") }}
              </div>
              <div class="footer__menu">
                <router-link
                  :to="$localePath({ name: 'About' })"
                  class="menu__item"
                >
                  {{ $t("menu.about") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Safety' })"
                  class="menu__item"
                >
                  {{ $t("menu.safe") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="menu__item"
                >
                  {{ $t("menu.platform") }}
                </router-link>
                <router-link
                  :to="$localePath({ name: 'Contacts' })"
                  class="menu__item"
                >
                  {{ $t("menu.contacts") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="footer__text">
            <p v-for="item in $t('footerText')" :key="item">
              <span v-html="item"></span>
            </p>
          </div>
        </div>
        <div class="col-12">
          <p class="copy">ASX 200 © {{ getYear }}, all rights reserved</p>
        </div>
        <div class="col-12">
          <p class="copy">FCA Reference ID: 642239</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  background: $black;
  color: $white;
  padding: 50px 0 60px;
}
.footer__text {
  font-size: 14px;
  color: $gray2;
}
.copy {
  color: $gray2;
  font-size: 16px;
}
.footer__logo {
  display: inline-block;
  width: 158px;
}
.footer__soc-link {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
  color: $white;

  .link-icon {
    font-size: 24px;
    color: $green;
    line-height: 1;
  }

  &:hover {
    color: $green;
  }
}
.footer__title {
  color: $white;
  margin-bottom: 20px;

  a {
    color: $white;
  }
}
.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.menu__item {
  color: $white;
  &:hover {
    text-decoration: underline;
  }
}
</style>
